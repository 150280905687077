.navigation
  @apply flex items-center justify-between z-10
  @apply h-[85px] md:h-[105px] xl:h-[130px]

  .logo
    @apply flex select-none mr-[30px]

    &.logoNonClickable
      // On the homepage, we pretend it’s not a link.
      cursor: default
      pointer-events: none

    & > div
      @apply align-middle

  .earlyAccessBadge
    @apply ml-[5px]

  .links
    @apply flex

    & > * + *
      @apply ml-[30px] inline-flex items-baseline

  .link
    @apply flex items-center font-neutral leading-[30px] transition
    @apply hover:text-blue focus:text-blue focus:outline-none

    :global(.dark) &
      @apply hover:text-green focus:text-green

    &:hover
      .earlyAccessBadge
        @apply bg-blue

        :global(.dark) &
          @apply bg-green

      .earlyAccessBadgeText
        @apply text-white

        :global(.dark) &
          @apply text-black

    &.linkActivePage
      @apply text-blue font-median

      :global(.dark) &
        @apply text-green

  .socialIcons
    @apply flex

    & > * + *
      @apply ml-[10px]

  .featuredIconCircle
    @apply flex items-center justify-center w-[45px] h-[45px] ml-[20px]
    @apply rounded-full cursor-pointer transition
    @apply bg-grey-200 hover:bg-green-500

  .featuredIcon
    @apply transition w-[18px]

  .ctaButton
    @apply w-[180px] ml-[40px]

.mobileMenu
  @apply fixed flex flex-col items-start
  @apply w-full h-full left-0 top-0 transition bg-white
  @apply pt-[35px] pb-[60px] px-[32px] opacity-0 pointer-events-none z-50

  &.mobileMenuShown
    @apply opacity-100 pointer-events-auto

  .closeIcon
    @apply absolute top-[28px] right-[40px] w-[36px] p-[12px] text-black cursor-pointer z-20

  .mobileLinks
    @apply mt-[60px]

    & > * + *
      @apply mt-[15px] sm:mt-[20px]
      @apply flex items-center

  .count
    @apply inline-block
    @apply w-[25px] h-[25px] leading-[25px]
    @apply bg-grey-100 rounded-full
    @apply text-[15px] text-center
    @apply ml-[8px] align-middle

  .earlyAccessBadge
    @apply ml-[8px] w-[25px] h-[25px] align-middle

  .earlyAccessBadgeText
    @apply leading-[25px]

  .mobileLink
    @apply font-bold leading-[36px] transition select-none
    @apply hover:text-blue-500 focus:text-blue-500 focus:outline-none sm:leading-[44px]
    @apply inline-block mr-[8px]

    &:hover
      .earlyAccessBadge
        @apply bg-blue

        :global(.dark) &
          @apply bg-green

      .earlyAccessBadgeText
        @apply text-white

        :global(.dark) &
          @apply text-black

  .mobileMenuFooter
    @apply flex mt-auto
    // According to designs, whole footer is shifted by 12px to left
    @apply ml-[-12px]

  .mobileSocialIcons
    @apply flex items-center justify-center

    & > * + *
      @apply ml-[10px]

  .mobileCtaButton
    @apply w-[180px] ml-[40px] justify-center

:global(.dark)
  .navigation

    .link
      @apply hover:text-green-500 focus:text-green-500

    .socialIconWrapper:hover .socialIcon
      @apply text-green-500

    .featuredIconCircle
      @apply bg-grey-800 hover:bg-green-500 focus:bg-green-500 focus:outline-none

      &:hover,
      &:focus
        .featuredIcon
          @apply text-black

    .count
      @apply bg-grey-800

  .mobileMenu
    @apply bg-grey-800

    .closeIcon
      @apply text-white

    .mobileLink
      @apply hover:text-green-500 focus:text-green-500

    .count
      @apply bg-grey-700
